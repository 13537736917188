h1, h2, h3, h4, h5 {
  font-weight: normal;
  margin: 0;
}

h1 {
  font-size: 54px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

@media (max-width: 1200px) {
  h1 {
    font-size: 54px;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 24px;
  }
}

@media (max-width: 1024px) {
  h1 {
    font-size: 44px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 24px;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 34px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 24px;
  }
}